import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: ${(props) => props.theme.containerWidths.medium};
  margin-left: auto;
  margin-right: auto;

  .padded-section {
    p {
      + p {
        margin-top: 8px;
      }

      + div {
        margin-top: 22px;
      }
    }
  }

  .gatsby-image-wrapper {
    margin-top: -10px;
    margin-bottom: 20px;
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    section {
      + .padded-section {
        padding-top: 0;
      }
    }
  }
`

const Competition = () => {
  return (
    <Wrapper className="wrap">
      <section className="section">
        <h3 className="headline--reunion center-align">Seint Glow-Up Competition</h3>
        <p>
          Get ready for Season 4 of Seint’s Glow-Up Competition! We loved seeing the creativity and artistry during
          our last Glow-Up Competition, and we can’t wait to see you bring the heat for this year’s showdown!
        </p>
        <p>
          The three finalists competing for the title of Seint’s Glow-Up Champion in the third round will complete
          their looks LIVE at the event in order for Over the Rainbow attendees to have the opportunity to watch the
          magic happen before voting. At the end of the Reunion, Cara will announce the official winner. First place
          will receive $1,500 USD, second place will receive $1,000 USD, and third place will receive $800 USD.
        </p>
        <h3>Round 1 — Graceful Glow</h3>
        <p>
          This makeup look aims to capture the essence of magic and kindness. The soft, shimmering hues of iridescent
          pinks and purples reflect Glenda's enchanting presence, while touches of gold and silver add a celestial
          touch. The makeup emphasizes radiant and glowing features, mirroring the character's positive energy and
          grace. This makeup look is a tribute to the timeless charm of Glenda, bringing a touch of magic and elegance
          to the wearer.
        </p>
        <p>
          Submit your dazzling looks <a style={{ textDecoration: 'underline' }} href="https://docs.google.com/forms/d/e/1FAIpQLSdXVI1FVVb95d1f3BRwaIbAzCu3TYEpQdNbpIS2JnXy8Pob5Q/viewform">HERE!</a> The
          Seint team will review all entries and select the top 10 finalists based on creativity and execution.
        </p>
        <p>
          Round 1 open for submissions: Monday, August 19th - Monday, August 26th
        </p>
        <h3>Round 2 — Glamour Girl</h3>
        <p>
          The 1930’s glamour makeup look draws inspiration from the Golden Age of Hollywood, a glamorous era in film
          history. Characterized by sophistication and elegance, the focus was on achieving a flawless complexion with
          a matte finish, accentuating the eyes with dark, defined lashes and eyeshadow. Classic red lips became a
          signature element, conveying a sense of allure and confidence. The 1930’s Glamour Girl makeup look embodies
          the timeless beauty standards of an era that celebrated Hollywood's leading ladies and their captivating
          on-screen presence.
        </p>
        <p>
          Round 2 open for submissions: TBD
        </p>
        <h3>Round 3 — Over the Rainbow</h3>
        <p>
          With vibrant hues and boundless creativity, the Over the Rainbow look draws inspiration from the
          kaleidoscopic beauty of a rainbow. This challenge aims to capture the enchanting and whimsical essence of a
          spectrum of colors. Each shade represents a facet of positivity, from the fiery passion of red to the serene
          calmness of indigo. The intention is to evoke a sense of joy and playfulness, encouraging you to embrace your
          uniqueness and express yourself boldly through the artistry of makeup. Just as a rainbow emerges after a
          storm, this makeup look symbolizes the beauty that can arise from embracing diversity and one's true colors.
        </p>
        <p>
          Round 3 open for submissions: TBD
        </p>
      </section>
    </Wrapper>
  )
}

export default Competition
